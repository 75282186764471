h2 {
  font-size: 72px !important;
  font-weight: 900 !important;
  color: rgba(255, 255, 255, 0);
  margin-bottom: 66px !important;
  text-shadow: 0px 0px 21px rgba(0, 0, 0, 0.5);
  font-family:'Inknut Antiqua', light;
}
h3 {
  font-size: 24px !important;
  font-weight: 800 !important;
  color: #fff;
  font-family:'Inknut Antiqua', serif;
}
h2, h3, h4, h5, h6 {
  text-transform: uppercase !important;
}
p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  font-family:'Inknut Antiqua', serif;
}
a{
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #fff !important;
  text-transform: uppercase !important;
  transition: all .5s;
  font-family:'Inknut Antiqua', serif;
}
.mg-b-0{
  margin-bottom: 0px;
}
.mg-t-0{
  margin-top: 0px;
}
.mg-b-10{
  margin-bottom: 10px;
}
.mg-t-10{
  margin-top: 10px;
}
.mg-b-80{
  margin-bottom: 80px;
}
.mg-t-80{
  margin-top: 80px;
}
.mg-b-100{
  margin-bottom: 100px;
}
.mg-t-100{
  margin-top: 100px;
}
.pd-b-0{
  padding-bottom: 0px;
}
.pd-t-0{
  padding-top: 0px;
}
.pd-b-80{
  padding-bottom: 80px;
}
.pd-t-80{
  padding-top: 80px;
}
.pd-b-100{
  padding-bottom: 100px;
}
.pd-t-100{
  padding-top: 100px;
}
.video__container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.video__container video{
  width: 100%;
  height: 120%;
  object-fit: cover;
}
