.hero{
    position: relative;
    text-align: center;
    padding-top:100px !important;
    z-index: 1000;
}
.hero h2{
    font-size: 86px !important;
    color: #ffffff00 !important;
    -webkit-text-stroke: 2px #F8F8F8;
    margin-top: 250px !important;
}
.hero__content{
    position: relative;
    z-index: 1000;
}
.hero__bg__overlay{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position:right center , left center;
    z-index: 500;
    /* background:; */
}
.hero__content img{
    display: block;
    margin: 20px auto;
    max-width:100%;
    border-radius: 50%;
    box-shadow: 0 0 30px 2px rgba(255, 255, 255, 0);
    background: #f8f8f800;
    width: 300px;
}
.hero__content p{
    color: #fff !important;
    font-size:18px;
    font-weight: 600;
}
.hero__content p.p1{
    margin-top:20px !important;
}
.hero__content h3{
    margin-bottom: 70px;
}
.hero__content h4{
    color: #fff;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom:50px;
    font-size: 18px;
}
.hero__content a{
    font-size: 20px !important;
    padding: 20px 40px;
}
.hero a.whitepaper{
    border: 1px solid #171717;
    color: rgb(255, 255, 255);
    box-shadow: 0 0 5px 1px rgb(24, 23, 23);
}
a.mint{
    border: 1px solid #686868 !important;
    color: #a9a3a3 !important;
    background: none;
    margin-left: 30px;
}
a.mint:hover{
    /* border: 1px solid #fff !important; */
    color: rgb(255, 255, 255) !important;
    background: #5e5e5e;
}
.hero img{
    margin-bottom: 50px;
}

.timer{
    padding: 0px 200px;
    margin-bottom: 60px;
}
.timer__box{
    padding: 20px 0px;
    border: 1px solid #4f4e4e;
    text-align: center;
    background: linear-gradient(180deg, rgba(24, 24, 24, 0.571) 0%, rgba(33, 32, 32, 0.531) 100%);
    box-shadow: 3px 3px 0px 1px #242323;
}
.timer__box h2{
    color: #9e9797 !important;
    margin-bottom: 15px !important;
    font-size: 60px !important;
}
.timer__box h4{
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 30px;
}
.MuiModal-root{
    z-index: 5000 !important;
}

@media only screen and (max-width:1399px){
    .timer{
        padding: 0px 100px;
    }
    .timer__box h4{
        font-size: 20px;
    }
}

@media only screen and (max-width:1199px){
    .timer{
        padding: 0px 200px;
    }
    .timer .col-md-3{
        width: 50%;
    }
    .timer__box{
        margin-bottom: 20px;
    }
    .sec-7 h2 {
        font-size: 45px !important;
    }
    .sec-6 {
        margin-top: 227px !important;
    }
    .sec-5 img {
        bottom: -77px !important;
    }
}

@media only screen and (max-width:992px){
    .hero__bg__overlay{
        background: linear-gradient(357deg, #ffffff00, #ffffff00);
    }
    .timer{
        padding: 0px 100px;
    }
    .about__content {
        padding-right: 0px !important;
    }
    .feature{
        text-align: center;
    }
    .hero{
        padding-bottom: 50px !important;
    }
    .about,.features,.utility,.roadmap,.team{
        text-align: center !important;
        padding-bottom: 0 !important;
    }
    .faq{
        text-align: center;
    }
}

@media only screen and (max-width:767px){
    .timer{
        padding: 0px 50px;
    }
}
@media only screen and (max-width:499px){
    .timer .col-md-3{
        width: 100%;
    }
    h2{
        font-size: 50px;
    }
    .hero a.whitepaper{
        width: 100%;
    display: block;
    margin-bottom: 40px;
    }
    .hero a.mint{
        margin-left: 0 !important;
    }
}

@media only screen and (max-width:600px){
    .about__img .col-md-6 {
        width: 100% !important;
    }
}