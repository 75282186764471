.faq{
    position: relative;
    z-index: 1000;
}
.faq h2{
    color: #ffffff;
    text-shadow: 0px 0px 21px rgba(56, 56, 56, 0.5);
}
.question{
    border-bottom: 1px solid #414141;
    padding: 20px 40px;
    transition: all .5s ease-in-out;
    margin-bottom: 20px;
    /* box-shadow: 3px 3px 0 0.5px rgb(232, 5, 5); */
    /* background: linear-gradient(180deg, rgba(24, 24, 24, 0.571) 0%, rgba(33, 32, 32, 0.531) 100%); */
}
.question h3{
    display: flex;
    margin: 0;
    align-items: center;
    cursor: pointer;
}
.question button{
    width: 100%;
    text-align: left;
    background: transparent;
    color: #fff;
    border: none;
    padding: 0;
    font-weight: 800;
}
.answer{
    transform: scaleY(0);
    height: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    transform-origin: top;
}
.answer p{
    color: #fff;
    margin-top: 20px !important;
    font-size: 17px;
    margin-left: 35px;
}
.answer ul{
    margin-left: 55px;
    padding: 0;
}
.answer ul li{
    color: #fff;
}
.question img{
    width: 25px;
    filter: brightness(1) invert(1);
    margin-right: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    
}
.rotate-icon{
    filter: brightness(0.5) !important;
    transform: rotate(45deg);
}
.button-color{
    color:rgb(16, 16, 16) !important;
}
.question-bg{
    background: transparent;
    background-color: #fffdfd00;
}
.show-answer{
    transform: scaleY(1) !important;
    height: auto !important;
    overflow: visible !important;
}

@media only screen and (max-width:600px){
    .question h3{
        font-size:1.1rem !important;
    }
}