.team{
    position: relative;
    z-index: 1000;
}
.team .row{
    justify-content: center;
}
.team h2{
    color: #ffffff;
    text-shadow: 0px 0px 21px rgba(92, 92, 92, 0.5);
    text-align: center;
}
.team__member img{
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    margin-top: -150px;
    /* border: 1px solid #e20202; */
    /* margin-bottom:20px */
}
.team__member{
    text-align: center;
    border: 0px solid #2e2d2d;
    margin-top: 100px;

    /* height: 100%; */
}
.member__details{
    /* background: linear-gradient(180deg, rgba(24, 24, 24, 0.703) 0%, rgba(33, 32, 32, 0.423) 100%); */
    /* -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px); */
    /* border: 1px solid #e20202; */
    border-top: none;
    /* border-radius: 0 0 1rem 1rem; */
    padding: 20px 30px;
    min-height: 150px;
}
.member__details p{
    color: #fff;
}

@media only screen and (max-width:992px){
    .team .col-md-4{
        width: 50%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:767px){
    .team .col-md-4{
        width: 100%;
    }
}