.about{
    position: relative;
    z-index: 1000;
    background: linear-gradient(179deg, rgba(252, 252, 252, 0)55, 255, 255);
}
.about h2{
    font-size: 60px;
    color: #056ca3;
    margin-top: 80px;
    text-shadow: 0px 0px 21px rgba(72, 72, 72, 0.5);
}
.about__content{
    padding-right: 30px;
}
.about p{
    color: #fff;
    font-size:17px;
}
.about__img img{
    width: 60%;
    margin-top: 100px;
    border-radius:1rem;
    transition: all .3s ease-in-out;
    /* box-shadow: 0 0 10px 1px rgba(255, 8, 8, 0.881); */
}
/* .about__img img:hover{
    transform: scale(1.03);
} */
.about__para{
    /*background: linear-gradient(180deg, rgba(24, 24, 24, 0.571) 0%, rgba(33, 32, 32, 0.531) 100%);*/
    /*border: 1px solid #282828;*/
    padding: 30px;
    /*box-shadow:10px 10px 0 1px rgb(1, 1, 1);*/
}
.mobile-only{
    display: none;
}

@media only screen and (max-width:992px){
    .desktop-only{
        display: none;
    }
    .mobile-only{
        display: block;
    }
    .about .col-md-6{
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
    }
    .about__img .col-md-6{
        width: 50%;
    }
    .about__img img{
        width: 80%;
    }
}
